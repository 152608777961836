<template>
  <div id="newsList">
    <LargeContainer>
      <div v-if="errorLoading" class="errorBox">
        データはありません
      </div>
      <newsListBlock :newsItems="posts.data" />
      <div class="buttonWrapper">
        <Button
          class="btnPager"
          :class="{ disabled: prevDisabled }"
          @click="prevPage()"
          >Prev</Button
        >
        <Button class="btnPager" @click="nextPage()">Next</Button>
      </div>
    </LargeContainer>
  </div>
</template>

<script>
import axios from "axios";
import config from "../constants/config";
import { mapActions } from "vuex";
import newsListBlock from "@/components/organisms/newsListBlock.vue";
const endpoint = config.ENDPOINT;
export default {
  name: "newsList",
  components: {
    newsListBlock
  },
  data() {
    return {
      currentPage: 1,
      errorLoading: false,
      posts: {
        data: [],
        isLoaded: false
      }
    };
  },
  computed: {
    prevDisabled() {
      return this.currentPage === 1;
    }
  },
  created() {
    this.set();
  },
  methods: {
    ...mapActions({
      showLoading: "Utility/showLoading",
      hideLoading: "Utility/hideLoading"
    }),
    set() {
      this.requestNews();
    },
    async requestNews() {
      this.showLoading();
      const url = `${endpoint}posts.php`;
      try {
        const result = await axios.get(url, {
          params: {
            page: this.currentPage
          }
        });
        if (result.data.error !== undefined) {
          this.errorLoading = true;
          //データの削除
          this.posts.data = [];
          return;
        }
        // console.log("結果", result);
        // this.errorLoading = false;
        // //テスト用の配列を複製して数を増やす
        // for (let i = 0; i < 4; i++) {
        //   result.data.forEach(item => {
        //     this.posts.data.push(item);
        //   });
        // }
        //本番用
        this.posts.data = result.data;
      } catch (e) {
        console.log(e);
      } finally {
        this.hideLoading();
      }
    },
    nextPage() {
      if (this.posts.data.length === 0) return;
      this.currentPage++;
      this.requestNews();
    },
    prevPage() {
      if (this.currentPage === 1) return;
      this.currentPage--;
      this.requestNews();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styls/variables.scss";
#newsList {
  .errorBox {
    margin: setSingleMargin(2);
    text-align: center;
  }
  .buttonWrapper {
    .btnPager {
      margin: setSingleMargin(1);
    }
  }
}
</style>
