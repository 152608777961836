<template>
  <div class="newsCard" @click="onClick()">
    <div class="image" :style="returnBgUrl"></div>
    <div class="title">
      <span v-html="sanitizedTitleText"></span>
    </div>
    <div class="date">
      {{ displayDate }}
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import sanitizeHtml from "sanitize-html";
export default {
  name: "newsCard",
  props: {
    newsItem: {
      type: Object
    }
  },
  computed: {
    returnBgUrl() {
      if (this.newsItem.thumbnails === undefined) return;
      let styleObj = {};
      styleObj.backgroundImage = `url(${this.getImageSrc(
        this.newsItem.thumbnails
      )})`;
      return styleObj;
    },
    displayDate() {
      if (this.newsItem.modified === undefined) return "";
      return dayjs(this.newsItem.modified).format("YYYY/MM/DD H:m");
    },
    sanitizedTitleText() {
      return sanitizeHtml(this.newsItem.title.rendered);
    }
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
    getImageSrc(thumbnailsObj) {
      if (thumbnailsObj.sizes.medium !== undefined) {
        return thumbnailsObj.sizes.medium.source_url;
      } else {
        return thumbnailsObj.sizes.full.source_url;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styls/variables.scss";
.newsCard {
  .image {
    height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
    @include lessThan(md) {
      height: 250px;
    }
  }
  .title {
    margin: setSingleMargin(1) 0;
    font-size: 1.2rem;
    font-family: $font-family-header;
  }
  .date {
    font-size: 0.8rem;
  }
}
</style>
