<template>
  <div class="newsListBlock flex wrap">
    <div
      v-for="(item, idx) in newsItems"
      :key="idx"
      class="cardWrapper"
      @click="selectNews(item)"
      v-wave
    >
      <NewsCard :newsItem="item" />
    </div>
  </div>
</template>

<script>
import NewsCard from "@/components/atoms/NewsCard.vue";
export default {
  name: "newsListBlock",
  components: {
    NewsCard
  },
  props: {
    newsItems: {
      type: Array
    }
  },
  methods: {
    selectNews(item) {
      this.$router.push({
        name: "NewsDetail",
        params: { news_id: item.id }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styls/variables.scss";
.newsListBlock {
  .cardWrapper {
    width: 25%;
    box-sizing: border-box;
    padding: setSingleMargin(1);
    @include lessThan(md) {
      width: 50%;
    }
    @include lessThan(sm) {
      width: 100%;
    }
  }
}
</style>
